<template>
  <div class="sysuser">
    <div class="tab_txt">
      <div class="input-with">
        <el-input
          placeholder="请输入真实姓名"
          v-model="queryInfo.userName"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
        <el-button type="primary" @click="userinfoClick">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="listData"
        stripe
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >:reserve-selection="true" />
        <el-table-column label="序号" align="center" prop="id" width="80px">
          <template slot-scope="scope">
            <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" align="center">
          <template v-slot="{ row }">
            <span>{{ row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="真实姓名" prop="userName" align="center"></el-table-column>
        <el-table-column label="角色" prop="roleName" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" align="center"></el-table-column>
        <el-table-column label="最近登录时间" prop="loginTime" align="center"></el-table-column>
        <!-- <el-table-column label="登录状态" prop="" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              active-color="#FD523F"
              inactive-color="#DEDEDE"
              :active-value="'0'"
              active-text="启用"
              :inactive-value="'1'"
              inactive-text="禁用"
              @change="switchang(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column> -->
        <el-table-column label="登录次数" prop="count" align="center"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
         
        >
          <template slot-scope="scope">
            <el-button style="color: #146aff" type="text" @click="edidadmin(scope.row)"
              >编辑
            </el-button>
            <el-button style="color: #ff4646" type="text" @click="deleteuser(scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="85px">
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="真实姓名:" prop="userName">
          <el-input v-model="form.userName" placeholder="请输入真实姓名" />
        </el-form-item>
        <!-- <el-form-item label="是否启用:" prop="commodityName">
          <el-radio-group v-model="form.commodityName">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="用户角色:" prop="roleId">
          <el-radio-group v-model="form.roleId">
            <el-radio v-for="item in rolelist" :key="item.id" :label="item.id">{{
              item.roleName
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { adminuser, addysyuser, editysyuser, rolelistAll, getdetail,deleteuser } from '@/api/system'
export default {
  name: 'user',
  data() {
    return {
      listData: [],
      // 遮罩层
      loading: false,
      //弹窗名字
      title:null,
      // 总条数
      total: 0,
      form: {
        departmentId: null,
        email: null,
        mobile: null,
        positionId: null,
        roleId: null,
        sex: null,
        userName: null
      },
      //验证必填
      rules: {
        userName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入手机号', trigger: 'blur' }
        ],
        roleId: [{ required: true, message: '请勾选角色', trigger: 'change' }]
      },
      open: false,
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        userName: null
      },
      //所有角色
      rolelist: []
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询用户一览
    async search() {
      this.loading = true
      const res = await adminuser(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      }
    },
    async rolelsit() {
      const res = await rolelistAll()
      if (res.data.resultCode == 200) {
        this.rolelist = res.data.data
      }
    },
    userinfoClick() {
      this.open = true
      this.title='新增'
      this.rolelsit()
      this.form = {
        departmentId: null,
        email: null,
        mobile: null,
        positionId: null,
        roleId: null,
        sex: null,
        userName: null
      }
    },
    //编辑获取用户详情
    edidadmin(row) {
      this.rolelsit()
      this.open=true
      this.title='编辑'
      getdetail({ id: row.id }).then((response) => {
        if (response.data.resultCode == 200) {
        this.form=response.data.data
        } 
      })
    },
    //新增系统用户
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.id == null) {
            addysyuser(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('新增成功')
                this.open = false
                this.search()
              } else {
                this.$message.error(response.data.message)
              }
            })
          } else {
            editysyuser(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('编辑成功')
                this.open = false
                this.search()
              } else {
                this.$message.error(response.data.message)
              }
            })
          }
        }
      })
    },
    //取消
    cancel() {
      this.open = false
    },
    //删除系统用户
    deleteuser(row){
      const ids = row.id 
      this.$alert('您是否确认删除？').then(function () {
        return deleteuser({id:ids});
      }).then(() => {
        this.search()
        this.$message.success("删除成功");
      }).catch(() => { });
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.sysuser {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    background: #ffffff;
    margin-top: 20px;
    padding: 20px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;

    .input-with-select {
      width: 400px;
      float: right;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
</style>
